import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import Details from "./pages/Details";
import CheckoutPage from "./pages/CheckoutPage";
import { useEffect } from "react";
import Aboutus from "./pages/Aboutus";
import DailyMenu from "./pages/DailyMenu";
import { LoadingProvider } from "./context/loadingContext";
import { AppDataProvider } from "./context/appDataContext";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <LoadingProvider>
      <AppDataProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/detail" element={<Details />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/aboutus" element={<Aboutus />} />

          <Route path="/menu" element={<DailyMenu />} />

        </Routes>
      </AppDataProvider>
    </LoadingProvider>
  );
}
export default App;
