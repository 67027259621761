import React, { FunctionComponent } from "react";
import { Card, CardContent, CardMedia, Typography, Box, } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { red } from '@mui/material/colors';
import { Avatar } from '@mui/material';
import { CardHeader } from '@mui/material';
import { CardActions } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { useNavigate } from "react-router-dom";


import "./ProductCard.css";

type ProductCardType = {
  cake?: any;
};
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ProductCard: FunctionComponent<ProductCardType> = ({
  cake,
}) => {

  const theme = useTheme();
  const [showMore, setShowMore] = React.useState<boolean>(false);

  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const navigate = useNavigate();
  return (


    <Grid item xs={12} sm={12} lg={3}>


      <Card sx={{ maxWidth: 345 }}>
        <CardHeader


          title={cake.name}

        />
        <CardMedia
          component="img"
          height="200"

          image={cake.images && cake.images.length > 0 && cake.images[0].src && cake.images[0].src !== "" ? cake.images[0].src : "/Birthday-Cake-Illustration.jpg"}
          alt={cake.name}
          sx={{ objectFit: "contain" }}
        />
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {cake.description.replace(/<p>|<\/p>/g, '')}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          <IconButton aria-label="add to cart">
            <AddShoppingCartIcon
              onClick={() => navigate(`/detail?flavour=` + cake.id)}
            />

          </IconButton>

          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>
              {cake.description.replace(/<p>|<\/p>/g, '')}
            </Typography>

          </CardContent>
        </Collapse>
      </Card>

    </Grid>

  );
};

export default ProductCard;
