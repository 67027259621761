import { FunctionComponent, useState, ChangeEvent } from "react";
import { TextField, FormControl, InputLabel, NativeSelect, Typography, FormControlLabel, Radio, FormLabel, Grid, List, ListItemButton, ListItemText, Stack, Checkbox, FormHelperText } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Textarea from "@mui/joy/Textarea";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import ImageUpload from "../utility/ImageUpload";
import Chip from "@mui/material/Chip";
import dayjs, { Dayjs } from "dayjs";
import {
  Formik,
  FormikHelpers,
  FormikProps,
  Form,
  Field,
  FieldProps,
} from 'formik';

import "./CakeOrderDetails.css";

export interface OrderData {
  messageOnCake: string;
  pickuptime: Dayjs;
}

export interface CakeOrderDetailsProps {
  // onDetailsChange: (details: { [key: string]: string }) => void;
  // orderDetails: any;
  // errors: any;
  // validateOrderDetails: any;
  cakeDetails?: any;
  formik?: any;
}

const CakeOrderDetails: FunctionComponent<CakeOrderDetailsProps> = ({
  // onDetailsChange,
  // orderDetails,
  // errors,
  // validateOrderDetails,
  cakeDetails,
  formik,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [updatedPrice, setUpdatedPrice] = useState(0);
  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
  };

  const [showImageUpload, setShowImageUpload] = useState(false);
  const handleCustomThemeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setShowImageUpload(event.target.value === "yes");
  };
  // useEffect(() => {
  //   setUpdatedPrice(orderDetails.price);
  // }, [orderDetails.price]);

  const tomorrow = dayjs().add(2, "day");
  const fiveAM = dayjs().set("hour", 5).startOf("hour");
  const nineAM = dayjs().set("hour", 9).startOf("hour");

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          {/* Increase size of input label */}
          <InputLabel
            variant="standard"
            htmlFor="orderDetails.flavour"
            sx={{
              fontSize: {
                xs: "1.2rem",
                lg: "1.5rem",
              },
            }}
          >
            Cake Flavour
          </InputLabel>

          <NativeSelect
            // value={orderDetails.flavour}
            inputProps={{
              name: "orderDetails.flavour",
              id: "orderDetails.flavour",
            }}
            onBlur={formik.handleBlur}
            onChange={(e) => {

              // onDetailsChange({ flavour: e.target.value });
              formik.handleChange(e);
              // validateOrderDetails(orderDetails);
            }}
            sx={{
              fontSize: {
                xs: ".8rem",
                lg: "1rem",
              },
              fontWeight: "fontWeightLight",
              pt: {
                xs: ".8rem",
                lg: "1.2rem",
              },
            }}
            value={formik.values.orderDetails.flavour}
          >
            <option value={-1}>Select Flavour</option>
            {cakeDetails &&
              cakeDetails.map((cake: any) => (
                <option value={cake.id}>{cake.name}</option>
              ))}
            {/* <option value={10}>Rasmalai</option>
            <option value={20}>Gulab Jamun</option>
            <option value={30}>Blue Berry</option> */}
          </NativeSelect>
          {formik.touched.orderDetails?.flavour && formik.errors.orderDetails?.flavour && <FormHelperText error>{formik.errors.orderDetails.flavour}</FormHelperText>}

        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <FormLabel
            id="cake-size-label"
            sx={{
              fontSize: {
                xs: "1.2rem",
                lg: "1rem",
              },
            }}
          >
            Cake Size
          </FormLabel>
          <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={4}>
              <Chip
                label="Small(4 servings)"
                onClick={(event) => formik.setFieldValue("orderDetails.cakeSize", "0")}
                color={formik.values.orderDetails.cakeSize == "0" ? "primary" : "default"}
              />
            </Grid>
            <Grid item xs={4}>
              <Chip
                label="Medium(6 servings)"
                onClick={(event) => formik.setFieldValue("orderDetails.cakeSize", "1")}
                color={formik.values.orderDetails.cakeSize == "1" ? "primary" : "default"}
              />
            </Grid>
            <Grid item xs={4}>
              <Chip
                label="Large(8-10 servings)"
                onClick={(event) => formik.setFieldValue("orderDetails.cakeSize", "2")}
                color={formik.values.orderDetails.cakeSize == "2" ? "primary" : "default"}
              />
            </Grid>
            <Grid item xs={4}>
              <Chip
                label="XL(12-15 servings)"
                onClick={(event) => formik.setFieldValue("orderDetails.cakeSize", "3")}
                color={formik.values.orderDetails.cakeSize == "3" ? "primary" : "default"}
              />
            </Grid>
            <Grid item xs={4}>
              <Chip
                label="XXL(20-25 servings)"
                onClick={(event) => formik.setFieldValue("orderDetails.cakeSize", "4")}
                color={formik.values.orderDetails.cakeSize == "4" ? "primary" : "default"}
              />
            </Grid>
            <Grid item xs={4}>
              <Chip
                label="XXXL(30-35 servings)"
                onClick={(event) => formik.setFieldValue("orderDetails.cakeSize", "5")}
                color={formik.values.orderDetails.cakeSize == "5" ? "primary" : "default"}
              />
            </Grid>
          </Grid>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          {/* Increase size of input label */}
          <InputLabel
            variant="standard"
            htmlFor="twoTier"
            sx={{
              fontSize: {
                xs: "1.2rem",
                lg: "1.5rem",
              },
            }}
          >
            2 Tier
          </InputLabel>
          <NativeSelect
            value={formik.values.orderDetails.twoTier}
            inputProps={{
              name: "orderDetails.twoTier",
              id: "orderDetails.twoTier",
            }}
            sx={{
              fontSize: {
                xs: ".8rem",
                lg: "1rem",
              },
              fontWeight: "fontWeightLight",
              pt: {
                xs: ".8rem",
                lg: "1.2rem",
              },
            }}
            onChange={(e) => {
              // onDetailsChange({ twoTier: e.target.value });
              formik.handleChange(e);
            }}
          >
            <option value="no">No</option>
            <option value="yes">Yes</option>
          </NativeSelect>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            id="orderDetails.messageOnCake"
            label="Message on the cake"
            variant="standard"
            required
            error={Boolean(formik.touched.orderDetails?.messageOnCake && formik.errors.orderDetails?.messageOnCake)}
            helperText={formik.touched.orderDetails?.messageOnCake && formik.errors.orderDetails?.messageOnCake}
            onBlur={formik.handleBlur}
            value={formik.values.orderDetails.messageOnCake}
            onChange={(e) => {
              // onDetailsChange({ messageOnCake: e.target.value });
              // validateOrderDetails(orderDetails);
              formik.handleChange(e);
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker

              label="Pick up time"
              disablePast={true}
              minDate={tomorrow}
              slotProps={{
                textField: {
                  onBlur: formik.handleBlur,
                  error: Boolean(formik.touched.orderDetails?.pickuptime && formik.errors.orderDetails?.pickuptime),
                  helperText: formik.touched.orderDetails?.pickuptime && formik.errors.orderDetails?.pickuptime,
                },
              }}
              value={formik.values.orderDetails.pickuptime}

              onChange={(newValue) => {
                // onDetailsChange({ pickuptime: newValue.format("DD-MM-YYYY HH:mm") });
                // validateOrderDetails(orderDetails);
                formik.setFieldValue("orderDetails.pickuptime", newValue.format("DD-MM-YYYY HH:mm"));
              }}
              // renderInput={(props) => <TextField {...props} />}
              // inputFormat="DD-MM-YYYY HH:mm"
              shouldDisableTime={(value, view) =>
                view === 'hours' && (value.hour() > 20 || value.hour() < 8)
              }
              ampm={false}
            />
          </LocalizationProvider>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          {/* Increase size of input label */}
          <InputLabel
            variant="standard"
            htmlFor="customTheme"
            sx={{
              fontSize: {
                xs: "1.2rem",
                lg: "1.5rem",
              },
            }}
          >
            Custom Theme
            <FormHelperText>
              Additional Charges at the time of payment
            </FormHelperText>
          </InputLabel>
          {/* <Typography variant="caption" display="block" gutterBottom>
            Additional Charges applicable
          </Typography> */}

          <NativeSelect
            value={formik.values.orderDetails.customTheme}
            inputProps={{
              name: "orderDetails.customTheme",
              id: "orderDetails.customTheme",
            }}
            sx={{
              fontSize: {
                xs: ".8rem",
                lg: "1rem",
              },
              fontWeight: "fontWeightLight",
              pt: {
                xs: ".8rem",
                lg: "1.2rem",
              },
            }}
            onChange={(e) => {
              // onDetailsChange({ customTheme: e.target.value });
              formik.handleChange(e);
              handleCustomThemeChange(e);
            }}
          >
            <option value="no">No</option>
            <option value="yes">Yes</option>
          </NativeSelect>
        </FormControl>
      </Grid>
      {showImageUpload && (
        <Grid item xs={12}>
          <ImageUpload formik={formik} />
        </Grid>
      )}
      <Grid item xs={12}>
        <FormControl fullWidth>
          <Textarea
            name="Outlined"
            placeholder="More Instructions"
            variant="outlined"
            minRows={3}
            maxRows={4}
            value={formik.values.orderDetails.instructions}
            onChange={(e) => {
              // onDetailsChange({ instructions: e.target.value });
              formik.handleChange
            }}
          />
        </FormControl>
      </Grid>
      {/* <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end">
          <Button startDecorator={<Add />}>Add to cart</Button>
        </Box>
      </Grid> */}
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Price: {formik.values.orderDetails.price}€
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CakeOrderDetails;

