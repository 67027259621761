import { FunctionComponent } from "react";
import "./AboutUs.css";
import { useNavigate } from "react-router-dom";
import homeAboutUs from "../images/home-about-us.png";
const AboutUs: FunctionComponent = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/aboutus"); // Navigate to the /about route
  };
  return (
    <div className="about-us-card">
      <div className="frame-parent5">
        <img className="frame-icon" alt="" src={homeAboutUs} />
        <div className="frame-parent6">
          <div className="frame-wrapper6">
            <div className="frame-wrapper7">
              <div className="about-us-what-we-do-parent">
                <div className="about-us-what">What we do</div>
                <div className="indus-flavors-brings">
                  Indus Flavors brings the rich culinary essence of Kashmir and
                  northern Indian cities, inspired by the majestic
                  trans-Himalayan Indus River. Operating from a dedicated home
                  kitchen, our small team guarantees authentic tastes for every
                  meal. Opt for pick-up or trust our delivery partner to bring
                  our delicacies to your door. Unlike traditional restaurants,
                  we cook entirely from scratch daily, ensuring freshness. Thus,
                  we prefer pre-orders to craft meals tailored to our daily
                  demand.
                </div>
              </div>
            </div>
          </div>
          <div
            className="know-more-wrapper"
            onClick={handleButtonClick}
            style={{ cursor: "pointer" }}
          >
            <div className="know-more">Know More</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
