import { FunctionComponent, useState, useEffect, useContext } from "react";
import "./OrderDetails.css";
import Checkout from "./checkout/Checkout";
import customFetch from "../utils/api";
import LoadingIndicator from "./utility/LoadingIndicator";
import { useLoading } from "../context/loadingContext";
import { AppDataContext } from "../context/appDataContext";
import cakeIllustration from "../images/Birthday-Cake-Illustration.jpg";

interface OrderDetailsProps {
  flavour?: string;
}

const CakeOrderDetails: FunctionComponent<OrderDetailsProps> = ({ flavour }) => {
  // const [cakeDetails, setCakeDetails] = useState<any>(null);
  // const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const appDataContext = useContext(AppDataContext);

  if (!appDataContext) {
    throw new Error("AppDataContext is not provided");
  }
  const { cakeDetails, dataLoaded } = appDataContext;
  const { setLoading } = useLoading();
  console.log(flavour)

  return (

    <div className="detail-page-inner">
      <LoadingIndicator />
      {dataLoaded && cakeDetails &&
        <div className="frame-wrapper11">
          <div className="frame-wrapper12">
            <div className="frame-parent12">
              <div className="frame-wrapper13">
                <img
                  className="frame-child7"
                  alt=""
                  // find cake id using flavour and use the image from cakeDetails
                  // src={flavour && cakeDetails.find((cake: any) => cake.id === parseInt(flavour)) ? cakeDetails.find((cake: any) => cake.id === parseInt(flavour)).images[0].src : cakeIllustration}
                  src={cakeIllustration}
                />
              </div>

              <div className="input-form">

                <Checkout cakeDetails={cakeDetails} flavour={flavour} />
              </div>
            </div>
          </div>
        </div>
      }
    </div>

  );
};

export default CakeOrderDetails;
