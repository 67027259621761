// LoadingIndicator.tsx

import React from "react";
import { useLoading } from "../../context/loadingContext";
import CircularProgress from "@mui/material/CircularProgress";

const LoadingIndicator: React.FC = () => {
  const { loading } = useLoading();

  if (!loading) return null;

  return (
    <div className="loading-indicator">
      {/* Your loading spinner/icon goes here */}
      <CircularProgress />
    </div>
  );
};

export default LoadingIndicator;
