// Create reat component for image upload
import React, { useState, ChangeEvent } from "react";
import { Button, Grid, IconButton, Input, Paper } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { storage } from "../../utils/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import UserContext from "../../context/userContext";


export interface ImageUploadProps {
  formik: any;
}

const ImageUpload: React.FunctionComponent<ImageUploadProps> = ({
  formik,
}) => {
  const user_context = React.useContext(UserContext);
  const { orderDetails, setOrderDetails } = user_context;
  const [images, setImages] = useState<string[]>([]);

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      const newImages = files
        .slice(0, 5 - images.length)
        .map((file) => URL.createObjectURL(file));
      setImages((prevImages) => [...prevImages, ...newImages]);
      // setOrderDetails((prevDetails: any) => ({
      //   ...prevDetails,
      //   images: [...prevDetails.images, ...files],
      // }));
      formik.setFieldValue("orderDetails.images", [...formik.values.orderDetails.images, ...files]);
    }
  };

  const handleImageDelete = (index: number) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
  };

  return (
    <div>
      {images.length < 5 && (
        <Button
          variant="contained"
          component="label"
          sx={{
            marginBottom: "1rem",
          }}
        >
          Upload File
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={handleImageChange}
            hidden
          />
        </Button>
      )}
      <Grid container spacing={2}>
        {images.map((imageSrc, index) => (
          <Grid item xs={6} sm={3} key={index}>
            <Paper style={{ position: "relative" }}>
              <img
                src={imageSrc}
                alt={`Uploaded preview ${index}`}
                style={{ width: "100%", height: "auto" }}
              />
              <IconButton
                color="secondary"
                style={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                }}
                onClick={() => handleImageDelete(index)}
              >
                <DeleteIcon />
              </IconButton>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
const uploadFile = (file: any): Promise<string> => {
  return new Promise((resolve, reject) => {
    const storageRef = ref(storage, `images/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
        reject(error);
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log("File available at", downloadURL);
          resolve(downloadURL);
        });
      }
    );
  });
};
// const handleUpload = async (files: any) => {
//   const imagesUrls: string[] = [];
//   for (let i = 0; i < files.length; i++) {
//     const file = files[i];
//     uploadFile(file)
//       .then((downloadURL) => {
//         console.log("Download URL:", downloadURL);
//         imagesUrls.push(downloadURL);
//       })
//       .catch((error) => {
//         console.error("Error uploading file:", error);
//         imagesUrls.push("");
//       });
//   }
//   return imagesUrls;
// };
const handleUpload = async (files: any): Promise<string[]> => {
  const uploadPromises: Promise<string>[] = files.map((file: any) => {
    return uploadFile(file)
      .then((downloadURL) => {
        console.log("Download URL:", downloadURL);
        return downloadURL;
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        return ""; // Return empty string on error
      });
  });

  const imagesUrls: string[] = await Promise.all(uploadPromises);
  return imagesUrls;
};

export { handleUpload, ImageUpload as default };
