import { FunctionComponent } from "react";
import indusLogo from "../images/indusLogo.png";
import "./Footer.css";
const Footer: FunctionComponent = () => {
  return (
    <div className="footer">
      <div className="footer-inner">
        <div className="frame-parent8">
          <div className="screenshot-2023-08-13-at-1610-wrapper">
            <img
              className="screenshot-2023-08-13-at-16101"
              alt=""
              src={indusLogo}
            />
          </div>
          <div className="frame-wrapper9">
            <div className="frame-wrapper10">
              <div className="frame-parent9">
                <div className="frame-parent10">
                  <div className="contact-us-on-parent">
                    <b className="contact-us-on">Contact us</b>
                    <div className="graan-voor-visch-container">
                      +31 622998661
                    </div>
                  </div>
                  <div className="follow-us-on-parent">
                    <b className="contact-us-on">Follow us on</b>
                    <div className="">
                      <a
                        href="https://facebook.com/Industhefoodconnection"
                        className="social-icon"
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fab"
                          data-icon="facebook"
                          className="svg-inline--fa fa-facebook fa-w-16"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          color="#959595"
                          width="30px"
                        >
                          <path
                            fill="currentColor"
                            d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"
                          ></path>
                        </svg>
                      </a>
                      {/* <span className="bar">|</span> */}
                      <a
                        href="https://instagram.com/indus_the_food_connection"
                        className="social-icon"
                      >
                        <svg
                          width="30px"
                          height="30px"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fab"
                          data-icon="instagram"
                          className="svg-inline--fa fa-instagram fa-w-14"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          color="#959595"
                        >
                          <path
                            fill="currentColor"
                            d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                          ></path>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="frame-parent11">
                  <div className="address-parent">
                    <b className="contact-us-on">Address</b>
                    <div className="graan-voor-visch-container">
                      <p className="graan-voor-visch">
                        Graan Voor Visch, Hoofddorp,
                      </p>
                      <p className="graan-voor-visch">Netherlands</p>
                    </div>
                  </div>
                  <div className="opening-hours-parent">
                    <b className="opening-hours">OPENING HOURS</b>
                    <div className="graan-voor-visch-container">
                      <p className="graan-voor-visch">
                        Tue To Fri 1:00 PM - 8:00 PM
                      </p>
                      <p className="graan-voor-visch">
                        Sat To Sun 4:00 PM - 8:00 PM
                      </p>
                    </div>
                  </div>
                </div>
                <div className="frame-parent11">
                  <div className="address-parent">
                    <b className="contact-us-on">KVK Nummer</b>
                    <div className="graan-voor-visch-container">
                      <p className="graan-voor-visch">
                        83071172
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
