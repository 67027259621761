import { FunctionComponent } from "react";
import "./OptionsForm.css";
import dailyMenu from "../images/daily-menu.png";
import partyMenu from "../images/party-menu.png";
import cakeOption from "../images/cake-option.png";
import { useNavigate } from "react-router-dom";

const OptionsForm: FunctionComponent = () => {
  const navigate = useNavigate();

  const handleButtonClick = (path: string) => {
    navigate(path); // Navigate to the /about route
  };
  return (
    <div className="options-card">
      <div className="frame-parent4">
        <div className="rectangle-parent">
          <img className="rectangle-icon" alt="" src={dailyMenu} />
          {/* <img className="frame-child1" alt="" src="/rectangle-6.svg" /> */}
          <div className="daily-food-menu-parent"
            onClick={() => handleButtonClick("/menu")}
            style={{ cursor: "pointer" }}
          >
            <div className="daily-food-menu">Daily Food Menu</div>
            <div className="get-home-cooked">
              Get Home Cooked food from our regular food menu. Join Whatsapp
              group to get regular updates
            </div>
          </div>
        </div>
        <div className="rectangle-group">
          <img className="frame-child2" alt="" src={partyMenu} />
          {/* <img className="frame-child3" alt="" src="/rectangle-6.svg" /> */}
          <div className="party-orders-parent">
            <div className="daily-food-menu">Party Orders</div>
            <div className="get-home-cooked">
              We prepare food for parties upto 100 people. Reach out to us on
              Whatsapp to get menu for Party Orders
            </div>
          </div>
        </div>
        <div className="rectangle-container">
          <img className="rectangle-icon" alt="" src={cakeOption} />
          <div className="cakes-parent">
            <div className="daily-food-menu">Cakes</div>
            <div className="get-home-cooked">
              Get fresh cream cakes for any occasion. Get custom theme for your
              design
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OptionsForm;
