import React from "react";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import { AppDataContext } from "../../context/appDataContext";
import MenuCardComponent from "./MenuCardComponent";
import { CartContext } from '../../context/dmCartContext';

interface CartItem {
    id: string;
    menuItem: any;
    moreDetails: any;
    quantity: number;
}
interface OrderMenuProps {
    menu: any;
}
const OrderMenu: React.FunctionComponent<OrderMenuProps> = ({ menu }) => {
    const theme = useTheme();
    const [menuItems, setMenuItems] = React.useState([]);
    const cartContext = React.useContext(CartContext);
    if (!cartContext) {
        throw new Error("AppDataContext is not provided");
    }

    const { cartItems, setCartItems } = cartContext;

    // const appDataContext = React.useContext(AppDataContext);
    // if (!appDataContext) {
    //     throw new Error("AppDataContext is not provided");
    // }
    // const { dailyMenu, dataLoaded } = appDataContext;
    const [cart, setCart] = React.useState<any[]>([]); // Initialize cart state

    const [disableAddToCart, setDisableAddToCart] = React.useState<boolean>(false); // Initialize cart state


    const addToCart = (item: any, price: any, priceUpdated: boolean, moreDetails: any) => { // Function to add item to cart and update state
        const cartItem: CartItem = {
            // create unique id for each item
            id: Math.random().toString(36).substr(2, 9), // Generate a unique id for each cart item
            menuItem: { id: item.id, price: price, name: item.name, isPriceUpdated: priceUpdated },
            moreDetails: moreDetails,
            quantity: 1
        };
        setCartItems(prevCart => {
            // Check if item already exists in the cart
            const existingItemIndex = prevCart.findIndex((i: CartItem) => i.menuItem.id === item.id && JSON.stringify(i.moreDetails) === JSON.stringify(moreDetails));
            if (existingItemIndex !== -1) {
                // If item exists, update the quantity
                const updatedCart = [...prevCart];
                updatedCart[existingItemIndex].quantity = (updatedCart[existingItemIndex].quantity || 1) + 1;
                return updatedCart;
            } else {
                // If item does not exist, add it to the cart
                return [...prevCart, cartItem];
            }
        });
    };

    React.useEffect(() => {
        const totalCartItems = cartItems.reduce((total: number, item: CartItem) => total + (item.quantity || 0), 0);
        if (totalCartItems > 49) {
            setDisableAddToCart(true);
        } else {
            setDisableAddToCart(false);
        }
    }, [cartItems]);

    return (
        <Grid container spacing={2} >
            <Grid item xs={12} sm={12} lg={12}>


                <div>
                    {menu && menu.map((menu: any, index: number) => (
                        <div key={index}>
                            <MenuCardComponent key={menu.id} menu={menu} addToCart={addToCart} disableAddToCart={disableAddToCart} />

                            <Box sx={{ height: '20px' }} /> {/* Added spacing between the two Card components */}
                        </div>
                    ))}
                </div>
            </Grid>
        </Grid>
    );
}

export default OrderMenu;