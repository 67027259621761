import react, { createContext, useState } from 'react';

interface CartContextType {
    cartItems: any[];
    setCartItems: React.Dispatch<React.SetStateAction<any[]>>;
    cartTotal: number;
    setCartTotal: React.Dispatch<React.SetStateAction<number>>;
}

export const CartContext = createContext<CartContextType | null>(null);

const CartProvider = ({ children }: { children: React.ReactNode }) => {
    const [cartItems, setCartItems] = useState<any[]>([]);
    const [cartTotal, setCartTotal] = useState<number>(0);

    return (
        <CartContext.Provider value={{ cartItems, setCartItems, cartTotal, setCartTotal }}>
            {children}
        </CartContext.Provider>
    );
};

export default CartProvider;