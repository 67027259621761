import { FunctionComponent } from "react";
import "./CenterTagLine.css";
const CenterTagLine: FunctionComponent = () => {
  return (
    <div className="home-child">
      <div className="lorem-ipsum-dolor-sit-amet-con-parent">
        <div className="lorem-ipsum-dolor5">
          Explore our range of delicious Fresh Cream Cakes
        </div>
        <div className="lorem-ipsum-dolor6">
          Savor our Fresh cream or Fusion cakes with an airy sponge base
          generously adorned with whipped cream. Choose for a wide range of
          flavors a sweet, creamy delight, perfect for celebrations.
        </div>
      </div>
    </div>
  );
};

export default CenterTagLine;
