import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import foodMenuIllustration from '../../images/food_menu_illustration.png';

interface MenuCardComponentProps {
    menu: any;
    addToCart: (menu: any, price: any, priceUpdated: boolean, moreDetails: any) => void;
    disableAddToCart?: boolean;
}

const AttributeComponent: React.FunctionComponent<any> = ({ attribute, attributeName, moreDetails, setMoreDetails, hasError }) => {
    return (
        <div>
            <Typography variant="body1" component="div">
                {attribute.name}
            </Typography>

            <Grid container spacing={0} columns={{ xs: 12, sm: 12, md: 12 }}>
                {attribute.options.map((option: string, optionIndex: number) => {
                    return (
                        <Grid item xs={4} key={optionIndex}>
                            <Chip
                                label={option}
                                onClick={() => {
                                    const updatedMoreDetails = moreDetails[attributeName] === option
                                        ? { ...moreDetails, [attributeName]: undefined }
                                        : { ...moreDetails, [attributeName]: option };
                                    setMoreDetails(updatedMoreDetails);
                                }}
                                color={moreDetails[attributeName] === option ? "primary" : "default"}
                            />
                        </Grid>
                    )
                })}
                {/* Only show the error message when hasError state is true */}
                {hasError && !moreDetails[attributeName] && (
                    <Typography variant="body2" component="div" color="error">
                        {attribute.name} is required
                    </Typography>
                )}
            </Grid>
        </div>
    );
}

const ProductMetaDataComponent: React.FunctionComponent<any> = ({ field, attributeName, moreDetails, setMoreDetails, hasError, updatePrice }) => {
    return (
        <div>
            <Typography variant="body1" component="div">
                {field.label}
            </Typography>

            <Grid container spacing={0} columns={{ xs: 12, sm: 12, md: 12 }}>
                {field.options.choices.map((option: any, optionIndex: number) => {
                    return (
                        <Grid item xs={4} key={optionIndex}>
                            <Chip
                                label={option.label}
                                onClick={() => {
                                    const updatedMoreDetails = moreDetails[attributeName]["label"] === option.label
                                        ? { ...moreDetails, [attributeName]: undefined }
                                        : { ...moreDetails, [attributeName]: { label: option.label, pricing_amount: option.pricing_amount } };
                                    setMoreDetails(updatedMoreDetails);
                                }}
                                color={moreDetails[attributeName]["label"] === option.label ? "primary" : "default"}
                            />
                        </Grid>
                    )
                })}
                {/* Only show the error message when hasError state is true */}
                {hasError && !moreDetails[attributeName] && (
                    <Typography variant="body2" component="div" color="error">
                        {field.label} is required
                    </Typography>
                )}
            </Grid>
        </div>
    );
}
const didSelectedKeysChange = (prevObject: any, currObject: any, keys: string[]) => {
    for (let key of keys) {
        if (prevObject[key] !== currObject[key]) {
            return true;
        }
    }
    return false;
}

const MenuCardComponent: React.FunctionComponent<MenuCardComponentProps> = ({ menu, addToCart, disableAddToCart }) => {
    const theme = useTheme();
    const [showMore, setShowMore] = React.useState<boolean>(false);
    const [priceUpdated, setPriceUpdated] = React.useState<boolean>(false);
    const [moreDetails, setMoreDetails] = React.useState<any>(() => {
        let initialDetails: { [key: string]: any } = {

        };
        if (menu.attributes && menu.attributes.length > 0) {
            menu.attributes.map((attribute: any) => {
                if (attribute.name.toLowerCase().includes("spice")) {
                    initialDetails = {
                        ...initialDetails,
                        spice_level: "Medium Spicy"
                    }
                }
            }
            )


        }
        if (menu.meta_data && menu.meta_data.length > 0 && menu.meta_data.map((meta: any) => meta.value.fields && meta.value.fields.length > 0)) {
            menu.meta_data.map((meta: any) => {
                meta.value.fields.map((field: any) => {
                    field.options.choices.map((option: any) => {

                        if (option.selected) {
                            const attributeName = field.label.toLowerCase().replace(/ /g, '_').replace(/\//g, '_').replace(/\*/g, '');
                            initialDetails = {
                                ...initialDetails,
                                [attributeName]: {
                                    label: option.label,
                                    pricing_amount: option.pricing_amount
                                }
                            };
                        }
                    })
                })
            })

        }
        return initialDetails;
    });

    const prevmoreDetails = React.useRef(moreDetails);
    const keysToUpdatePrice = ['extra_paratha'];

    const [validations, setValidations] = React.useState<any>([]); // Initialize validations state
    const [hasError, setHasError] = React.useState(false);
    const [addtoCartClicked, setAddToCartClicked] = React.useState(false);
    const [price, setPrice] = React.useState(parseFloat(menu.price));


    const [open, setOpen] = React.useState(false);
    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };


    const handleValidation = async () => {
        const requiredFields = Object.keys(validations); // Get the required fields from the validations state
        const missingFields = requiredFields.filter((field) => !moreDetails[field]); // Filter the fields that are not present in the moreDetails state
        if (missingFields.length > 0) {
            setHasError(true);
        }
        else {
            setHasError(false);
        }
    }



    const handleAddToCart = async () => {
        await handleValidation();
        // Use a callback to get the most recent state
        setHasError(currentHasError => {
            if (!currentHasError) {
                // add to cart if no error
                addToCart(menu, price, priceUpdated, JSON.parse(JSON.stringify(moreDetails)));
                setOpen(true);
            }
            return currentHasError; // return the current state
        });
    }

    const updatePrice = (moreDetails: any) => {
        let newPrice = parseFloat(menu.price);

        if (Object.keys(moreDetails).some((key) => key.includes("extra"))) {
            const key = Object.keys(moreDetails).find((key) => key.includes("extra"));
            if (key !== undefined) {
                if (moreDetails[key].label === "Yes" && !priceUpdated) {
                    newPrice = newPrice + parseFloat(moreDetails[key].pricing_amount);
                    setPriceUpdated(true);
                }
                else if (moreDetails[key].label === "No" && priceUpdated) {
                    newPrice = newPrice - parseFloat(moreDetails[key].pricing_amount);
                    setPriceUpdated(false);
                }
                else
                    newPrice = parseFloat(menu.price);
            }
        }

        setPrice(newPrice);
    }
    React.useEffect(() => {
        if (addtoCartClicked) {
            handleValidation();
        }
        if (didSelectedKeysChange(prevmoreDetails.current, moreDetails, keysToUpdatePrice)) {
            updatePrice(moreDetails);
        }
        prevmoreDetails.current = moreDetails;
    }, [moreDetails]);




    return (
        <div>
            <Card sx={{ display: 'flex', flexDirection: 'column', [theme.breakpoints.up('sm')]: { flexDirection: 'row' } }}>
                <CardMedia
                    component="img"
                    sx={{ width: '100%', height: 200, [theme.breakpoints.up('sm')]: { width: 200 } }}
                    image={menu.images && menu.images.length > 0 && menu.images[0].src && menu.images[0].src !== "" ? menu.images[0].src : foodMenuIllustration}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography component="div" variant="h5">
                            {menu.name}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" component="div">
                            {showMore
                                ? menu.description.replace(/<p>|<\/p>/g, '')
                                : `${menu.description.replace(/<p>|<\/p>/g, '').substring(0, 150)}...`}
                            {menu.description.length > 150 && !showMore && (<a onClick={() => setShowMore(!showMore)} style={{ textDecoration: 'underline' }}>
                                {showMore ? 'Show Less' : 'Show More'}
                            </a>)}

                        </Typography>


                        {menu.attributes && menu.attributes.map((attribute: any, index: number) => {
                            const attributeName = attribute.name.toLowerCase().replace(/ /g, '_').replace(/\//g, '_').replace(/\*/g, '');
                            const isRequired = attribute.name.includes('*');
                            if (isRequired) {
                                // Add the required fields to the validations state
                                React.useEffect(() => {
                                    setValidations((prevValidations: any) => {
                                        return { ...prevValidations, [attributeName]: true };
                                    });
                                }, []);
                            }
                            if (attribute["visible"])
                                return (
                                    <AttributeComponent
                                        key={index}
                                        attribute={attribute}
                                        attributeName={attributeName}
                                        moreDetails={moreDetails}
                                        setMoreDetails={setMoreDetails}
                                        hasError={hasError}
                                    />
                                );
                        })}

                        {menu.meta_data && menu.meta_data.map((meta: any, index: number) => {
                            return meta.value.fields && meta.value.fields.length > 0 && meta.value.fields.map((field: any, index: number) => {
                                return (
                                    <ProductMetaDataComponent
                                        key={index}
                                        field={field}
                                        attributeName={field.label.toLowerCase().replace(/ /g, '_').replace(/\//g, '_').replace(/\*/g, '')}
                                        moreDetails={moreDetails}
                                        setMoreDetails={setMoreDetails}
                                        hasError={hasError}
                                        updatePrice={updatePrice}
                                    />
                                )
                            })
                        })}



                        <Grid item xs={12} container direction="row" alignItems="center" justifyContent="space-between">
                            <Typography variant="h6" gutterBottom>
                                Price: {price}€
                            </Typography>

                            <Button variant="contained"
                                endIcon={<AddShoppingCartIcon />}
                                disabled={disableAddToCart}
                                onClick={() => {
                                    setAddToCartClicked(true);
                                    handleAddToCart();

                                }}>


                                Add
                            </Button>
                            <Snackbar
                                open={open}
                                autoHideDuration={3000}
                                onClose={handleClose}
                                message="Item added to the order"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            />
                        </Grid>

                    </CardContent>

                </Box>

            </Card>


        </div>
    );
}
export default MenuCardComponent;