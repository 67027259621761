const whatsAppUrl = (resp: any) => {
  const url = `https://wa.me/31622998661?text=`;

  let msg = `My order with *Indus the food Connection*
*Order Details #${resp.data.id}*
`;
  for (let i = 0; i < resp.data.line_items.length; i++) {
    const item = resp.data.line_items[i];
    let cake_msg = `*Cake* - ${item.name}`;
    for (let j = 0; j < resp.data.meta_data.length; j++) {
      const meta = resp.data.meta_data[j];
      if (meta.key.toLowerCase().includes("image_url")) continue;

      const arr = meta.key.replace("_", " ").split(" ");
      for (var k = 0; k < arr.length; k++) {
        arr[k] = arr[k].charAt(0).toUpperCase() + arr[k].slice(1);
      }
      const meta_key = arr.join(" ");

      let meta_msg = `
  - *${meta_key}* - ${meta.value}`;
      cake_msg += meta_msg;
    }
    msg +=
      cake_msg +
      `
`;
  }
  msg += `
Thank You`;

  return `${url + encodeURIComponent(msg)}`;
};

const moreDetailsAttributeMapping: any = {
  spice_level: "Spice Level",
  quantity: "Quantity",
  rice_roti_option: "Rice/Roti Option",
  extra_paratha: "Extra Paratha",
};

const composeWhatsAppMessage = (order: any) => {
  const url = `https://wa.me/31622998661?text=`;
  let message = "*Order Details:* \n\n";

  // Add Item Name
  for (let item of order.data.line_items) {
    message += `*Item Name:* ${item.name} \n`;
    // Add quantity
    message += `*Quantity:* ${item.quantity} \n`;
    // Add Meta Data
    for (let meta of item.meta_data) {
      message += `${moreDetailsAttributeMapping[meta.display_key]}: _${meta.display_value}_ \n`;
    }

    message += "\n";
  }



  // Add Total Price
  message += `*Total Price:* ${order.data.currency_symbol}${order.data.total} \n\n`;

  message += `*Name:* ${order.data.billing.first_name} \n\n`;
  message += `*Phone:* ${order.data.billing.phone} \n\n`;

  // Add pickup slot
  let pickupSlot = order.data.meta_data.find((meta: { key: string }) => meta.key === "pickupSlot");
  if (pickupSlot) {
    message += `*Pickup Slot:* ${pickupSlot.value} \n\n`;
  }

  // Add Instructions
  let instructionData = order.data.meta_data.find((meta: { key: string }) => meta.key === "instruction");
  if (instructionData) {
    message += `*Instructions:* ${instructionData.value} \n`;
  }


  return `${url + encodeURIComponent(message)}`;
}

export { composeWhatsAppMessage, whatsAppUrl as default };