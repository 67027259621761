import { FunctionComponent, useContext } from "react";
import ProductCard from "./ProductCard";
import "./CakeGallery.css";
import { AppDataContext } from "../context/appDataContext";
import { useLoading } from "../context/loadingContext";
import Grid from '@mui/material/Grid';
import LoadingIndicator from "./utility/LoadingIndicator";

const CakeGallery: FunctionComponent = () => {
  const appDataContext = useContext(AppDataContext);

  if (!appDataContext) {
    throw new Error("AppDataContext is not provided");
  }
  const { cakeDetails, dataLoaded } = appDataContext;
  const { setLoading } = useLoading();
  return (
    <div className="how-it-works">
      <div className="frame-parent7">
        <Grid container spacing={2} direction="row" >

          {dataLoaded && cakeDetails && cakeDetails.map((cake: any) => {

            return <ProductCard cake={cake} />
          }
          )}

        </Grid>
        <LoadingIndicator />
      </div>
    </div>
  );
};

export default CakeGallery;
