import { FunctionComponent } from "react";
import Header from "../components/Header";
import Nav from "../components/Nav";
import Banner from "../components/Banner";
import OptionsForm from "../components/OptionsForm";
import AboutUs from "../components/AboutUs";
import HowItWorks from "../components/HowItWorks";
import CenterTagLine from "../components/CenterTagLine";
import CakeGallery from "../components/CakeGallery";
import Footer from "../components/Footer";
import { LoadingProvider } from "../context/loadingContext";
import { AppDataProvider } from "../context/appDataContext";
import "./Home.css";
const Home: FunctionComponent = () => {
  return (
    <div className="home">
      <Header />
      <Nav />
      <Banner />
      {/* <div className="center-tagline">
        <div className="dedicated-to-serving-freshly-p-parent">
          <div className="dedicated-to-serving">INDUS THE FOOD CONNECTION</div>
          <div className="dedicated-to-serving1">
            DEDICATED TO SERVING FRESHLY PREPARED AUTHENTIC INDIAN CUISINES AND
            FUSION CAKES
          </div>
        </div>
      </div> */}
      <OptionsForm />
      <AboutUs />
      <HowItWorks />
      <CenterTagLine />
      <LoadingProvider>
        <AppDataProvider>
          <CakeGallery />
        </AppDataProvider>
      </LoadingProvider>
      <Footer />
    </div>
  );
};

export default Home;
