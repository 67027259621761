import React, { FunctionComponent } from "react";
import Header from "../components/Header";
import Nav from "../components/Nav";
import Banner from "../components/Banner";
import OptionsForm from "../components/OptionsForm";
import AboutUs from "../components/AboutUs";
import HowItWorks from "../components/HowItWorks";
import CenterTagLine from "../components/CenterTagLine";
import CakeGallery from "../components/CakeGallery";
import Footer from "../components/Footer";
import OrderDetails from "../components/OrderDetails";
import UserState from "../context/userState";
import { LoadingProvider } from "../context/loadingContext";
import { AppDataProvider } from "../context/appDataContext";
import "./Details.css";
import { useParams, useLocation } from 'react-router-dom';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const Details: FunctionComponent = () => {
  let query = useQuery();
  let flavour = query.get("flavour");

  return (
    <div className="details">
      <Header />
      <Nav />
      <LoadingProvider>
        <AppDataProvider>
          <UserState>

            <OrderDetails flavour={flavour ? flavour : ""} />

          </UserState>
        </AppDataProvider>
      </LoadingProvider>
      {/* <OptionsForm />
      <AboutUs />
      <HowItWorks />
      <CenterTagLine />
      <CakeGallery /> */}
      <Footer />
    </div>
  );
};

export default Details;
