import React from "react";
import Header from "../components/Header";
import Nav from "../components/Nav";
import AboutUs from "../components/AboutUs";
import Footer from "../components/Footer";
import "./Home.css";
import "./Aboutus.css";
import aboutUsMain from "../images/about-us-main.jpg";

const Aboutus: React.FunctionComponent = () => {
  return (
    <div className="home">
      <Header />
      <Nav />
      <div className="abs-about-us-card">
        <div className="abs-frame-parent5">
          <img className="abs-frame-icon" alt="" src={aboutUsMain} />
          <div className="abs-frame-parent6">
            <div className="abs-frame-wrapper6">
              <div className="abs-frame-wrapper7">
                <div className="abs-about-us-what-we-do-parent">
                  <div className="abs-about-us-what">What we do</div>
                  <div className="abs-indus-flavors-brings">
                    <p>
                      Welcome to "Indus the food connection", where passion for
                      food meets tradition, and every dish tells a story. Our
                      journey began when our founder, driven by her undying
                      passion for baking and cooking, decided to leave the
                      corporate world behind. At Indus, we believe in
                      celebrating the rich culinary heritage of Kashmir, a
                      region known for its aromatic spices, delectable dishes,
                      and soul-warming flavors. Every dish we serve is a labor
                      of love, meticulously prepared to offer you a taste of
                      authentic Kashmiri cuisine.
                    </p>
                    <p>But that's not all.</p>
                    Our weekends are a culinary fiesta, courtesy of the
                    masterful hands of our co-chef, who brings to the table the
                    vibrant and diverse flavors of North Indian cuisine. As a
                    husband-wife duo, we complement each other in the kitchen,
                    combining our love for food and our shared vision to offer
                    an unparalleled dining experience to our guests.
                    <p>
                      At "Indus the food connection", we have consciously chosen
                      to cook from our home kitchen. This is not just a place
                      where dishes are prepared; it's where magic happens.
                      Unlike traditional restaurants, we prioritize freshness
                      over everything else. Every dish, every ingredient is
                      chosen with care, ensuring that what reaches your plate is
                      fresh, wholesome, and bursting with flavor.
                    </p>
                    <p>
                      For those with a sweet tooth, we have a special treat. Our
                      founder's penchant for baking is evident in our dessert
                      menu. Imagine biting into a fresh cream cake, its soft
                      layers melting in your mouth, but with a twist. Our cakes
                      are infused with the flavors of iconic Indian sweets - be
                      it the milky goodness of Rasmalai or the syrupy delight of
                      Gulabjamun. It's a fusion that's both surprising and
                      comforting, a taste that lingers.
                    </p>
                    <p>
                      Join us on this gastronomic journey, where traditional
                      recipes meet contemporary tastes, where every dish is a
                      testament to our love for food, and where you, our guest,
                      are at the heart of everything we do. Welcome to
                    </p>
                    <p>
                      "Indus the food connection". Rediscover food. Rediscover
                      passion.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="abs-know-more-wrapper">
              <div className="abs-know-more">Know More</div>
            </div> */}
          </div>
        </div>
      </div>

      {/* <AboutUs /> */}

      <Footer />
    </div>
  );
};

export default Aboutus;
