import * as React from "react";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import { Stack } from "@mui/material";

const products = [
  {
    name: "Product 1",
    desc: "A nice thing",
    price: "$9.99",
  },
  {
    name: "Product 2",
    desc: "Another thing",
    price: "$3.45",
  },
  {
    name: "Product 3",
    desc: "Something else",
    price: "$6.51",
  },
  {
    name: "Product 4",
    desc: "Best thing of all",
    price: "$14.11",
  },
  { name: "Shipping", desc: "", price: "Free" },
];

const addresses = ["1 MUI Drive", "Reactville", "Anytown", "99999", "USA"];
const payments = [
  { name: "Card type", detail: "Visa" },
  { name: "Card holder", detail: "Mr John Smith" },
  { name: "Card number", detail: "xxxx-xxxx-xxxx-1234" },
  { name: "Expiry date", detail: "04/2024" },
];

export interface OrderReviewProps {
  // userDetails: any;
  // orderDetails: any;
  cakeDetails: any;
  userDetailsFormik: any;
  orderDetailsFormik: any;
}

const cakeSizes = [
  "Small(4 servings)",
  "Medium(6 servings)",
  "Large(8-10 servings)",
  "XL(12-15 servings)",
  "XXL(15-20 servings)",
];

const Review: React.FunctionComponent<OrderReviewProps> = ({
  // userDetails,
  // orderDetails,
  cakeDetails,
  userDetailsFormik,
  orderDetailsFormik,
}) => {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Order summary
      </Typography>
      <List disablePadding>
        <Stack spacing={1}>
          <Stack direction="row" spacing={3}>
            <Typography variant="body2" color={"primary"}>
              Flavour:
            </Typography>
            <Typography variant="body2">
              {
                cakeDetails.find((cake: any) => cake.id == orderDetailsFormik.values.orderDetails.flavour)
                  .name
              }
            </Typography>
          </Stack>
          <Stack direction="row" spacing={3}>
            <Typography variant="body2" color={"primary"}>
              Message on Cake:
            </Typography>
            <Typography variant="body2">
              {orderDetailsFormik.values.orderDetails.messageOnCake}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={3}>
            <Typography variant="body2" color={"primary"}>
              Pick up time:
            </Typography>
            <Typography variant="body2">
              {orderDetailsFormik.values.orderDetails.pickuptime}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={3}>
            <Typography variant="body2" color={"primary"}>
              Cake Size:
            </Typography>
            <Typography variant="body2">
              {cakeSizes[parseInt(orderDetailsFormik.values.orderDetails.cakeSize)]}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={3}>
            <Typography variant="body2" color={"primary"}>
              Instructions:
            </Typography>
            <Typography variant="body2">{orderDetailsFormik.values.orderDetails.instructions}</Typography>
          </Stack>
        </Stack>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            {orderDetailsFormik.values.orderDetails.price}€
          </Typography>
        </ListItem>
      </List>
      <List disablePadding>
        <Stack spacing={1}>
          <Stack direction="row" spacing={3}>
            <Typography variant="body2" color={"primary"}>
              Name:
            </Typography>
            <Typography variant="body2">{userDetailsFormik.values.userDetails.userName}</Typography>
          </Stack>
          <Stack direction="row" spacing={3}>
            <Typography variant="body2" color={"primary"}>
              Phone:
            </Typography>
            <Typography variant="body2">{userDetailsFormik.values.userDetails.phone}</Typography>
          </Stack>
        </Stack>
      </List>
      {/* <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Shipping
          </Typography>
          <Typography gutterBottom>John Smith</Typography>
          <Typography gutterBottom>{addresses.join(", ")}</Typography>
        </Grid>
        <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Payment details
          </Typography>
          <Grid container>
            {payments.map((payment) => (
              <React.Fragment key={payment.name}>
                <Grid item xs={6}>
                  <Typography gutterBottom>{payment.name}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{payment.detail}</Typography>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
      </Grid> */}
    </React.Fragment>
  );
};

export default Review;
