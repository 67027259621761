import { FunctionComponent } from "react";
import "./Banner.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import banner1 from "../images/banner_1.png";
import banner2 from "../images/banner_2.png";
const Banner: FunctionComponent = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/detail"); // Navigate to the /about route
  };
  return (
    <div className="home-inner">
      <div className="frame-container">
        <div className="frame-div">
          <div className="frame-wrapper1">
            <div className="frame-parent1">
              <div className="frame-wrapper2">
                <div className="frame-wrapper3">
                  <div className="frame-parent2">
                    <div className="fresh-cream-cakes-for-every-oc-wrapper">
                      <div className="fresh-cream-cakes-container">
                        <p className="fresh-cream-cakes">Fresh Cream Cakes</p>
                        <p className="fresh-cream-cakes">for every occasion</p>
                      </div>
                    </div>
                    <div className="indulge-guilt-free-our-light-wrapper">
                      <div className="indulge-guilt-free-our">
                        Indulge Guilt-Free: Our Light, Low-Sugar, Home-Cooked
                        Fresh Cream Cakes Await!
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-wrapper4">
                <div className="order-online-now-wrapper">
                  <div
                    className="order-online-now"
                    onClick={handleButtonClick}
                    style={{ cursor: "pointer" }}
                  >
                    Order online now
                  </div>
                  {/* <Button
                    // className="order-online-now"
                    onClick={handleButtonClick}
                  >
                    Order online now
                  </Button> */}
                </div>
              </div>
            </div>
          </div>
          <div className="frame-wrapper5">
            <div className="frame-parent3">
              <img className="frame-item" alt="" src={banner1} />
              <img className="frame-item" alt="" src={banner2} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
