import React, { createContext, useState, useEffect, ReactNode } from 'react';
import customFetch from "../utils/api";
import { useLoading } from "./loadingContext";

interface AppDataContextType {
    cakeDetails: any;
    dailyMenu: any;
    dataLoaded: boolean;
}

export const AppDataContext = createContext<AppDataContextType | null>(null);


export const AppDataProvider = ({ children }: { children: ReactNode }) => {
    const [cakeDetails, setCakeDetails] = useState<any>(null);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [dailyMenu, setDailyMenu] = useState<any>(null);

    const { setLoading } = useLoading();

    useEffect(() => {
        customFetch(`${process.env.REACT_APP_API_URL}/cakes`, {}, setLoading)
            .then((response) => response.json())
            .then((data) => {
                setCakeDetails(data);
                setDataLoaded(true);
            })
            .catch((error) =>
                console.error("Error fetching product details:", error)
            );
    }, []);

    useEffect(() => {
        customFetch(`${process.env.REACT_APP_API_URL}/daily-menu`, {}, setLoading)
            .then((response) => response.json())
            .then((data) => {
                setDailyMenu(data);
                setDataLoaded(true);
            })
            .catch((error) =>
                console.error("Error fetching product details:", error)
            );
    }, []);

    return (
        <AppDataContext.Provider value={{ cakeDetails, dailyMenu, dataLoaded }}>
            {children}
        </AppDataContext.Provider>
    );
};