import React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import UserDeatils from "../checkout/UserDetails";
import { validateUserDetailsUtil } from "../utility/Validations";
import { CartContext } from "../../context/dmCartContext";
import TextField from '@mui/material/TextField';
import customFetch from "../../utils/api";
import { useLoading } from "../../context/loadingContext";
import LoadingIndicator from "../utility/LoadingIndicator";
import { composeWhatsAppMessage } from "../utility/WhatsappUrl";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import { FormHelperText } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';

interface PlaceOrderProps {
    menu: any;
}

const DailyMenuOrderSchema =
    Yup.object().shape({
        userDetails: Yup.object().shape({
            userName: Yup.string()
                .min(2, 'Too Short!')
                .max(50, 'Too Long!')
                .required('Name is required'),
            phone: Yup.string()
                .required('Phone number is required')
                .matches(/^(06\d{8}|\+31\d{9}|\+91\d{10})$/, "Must start with 06 or +31 or +91")
        }),
        pickupSlot: Yup.string()
            .required('Pickup slot is required')
            .notOneOf(["-1"], "Please select a pickup slot")
    });

const PlaceOrder: React.FunctionComponent<PlaceOrderProps> = ({ menu }) => {

    const cartContext = React.useContext(CartContext);
    if (!cartContext) {
        throw new Error("AppDataContext is not provided");
    }
    const { cartItems, setCartItems } = cartContext;

    const [instructions, setInstructions] = React.useState("");

    const [activeTimeSlots, setActiveTimeSlots] = React.useState<any[]>([]); // Initialize cart state



    const { loading, setLoading } = useLoading();

    const [isButtonClicked, setIsButtonClicked] = React.useState(false);
    const [submitOrder, setSubmitOrder] = React.useState(false);

    const dmOrderDetailsFormik = useFormik({
        initialValues: {
            userDetails: {
                userName: "",
                phone: "",
            },
            pickupSlot: "-1"
        },
        validationSchema: DailyMenuOrderSchema,
        onSubmit: values => {
            console.log(values)
        },
    })



    React.useEffect(() => {
        if (submitOrder) {
            // update the cart items
            console.log(cartItems);
            customFetch(
                `${process.env.REACT_APP_API_URL}/order/menu/create`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        cartItems,
                        ...dmOrderDetailsFormik.values.userDetails,
                        instructions,
                        ...{ pickupSlot: dmOrderDetailsFormik.values.pickupSlot }
                    }),
                },
                setLoading
            )
                .then((response) => response.json())
                .then((data) => {
                    console.log("Success:", data);

                    setCartItems([]);
                    setIsButtonClicked(false);
                    const url = composeWhatsAppMessage(data);
                    window.location.href = url;
                })
                .catch((error) => {
                    console.error("Error:", error);
                });

            setSubmitOrder(false);
        }
    }, [submitOrder]);

    const handleButtonClick = async () => {
        const errors = await dmOrderDetailsFormik.validateForm();
        if (Object.keys(errors).length === 0) {
            setSubmitOrder(true);
        }

    }

    const getActiveTimeSlots = () => {
        let newSlots: any[] = [];
        menu.forEach((item: any) => {
            item.attributes.map((attribute: any) => {
                if (attribute.name === "Pickup Slot") {
                    attribute.options.forEach((option: any) => {
                        newSlots = [...newSlots, option];
                    })
                }
            })
        });
        setActiveTimeSlots(newSlots);
    }
    React.useEffect(() => {
        getActiveTimeSlots();
    }, [menu]);



    return (
        <div>
            <div style={{ marginTop: '20px' }}>
                <UserDeatils

                    formik={dmOrderDetailsFormik} />

            </div>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    {/* Increase size of input label */}
                    <InputLabel
                        variant="standard"
                        htmlFor="customTheme"
                        sx={{
                            fontSize: {
                                xs: "1.2rem",
                                lg: "1.5rem",
                            },
                        }}
                    >
                        Pickup slot
                        <span > *</span>
                    </InputLabel>

                    <NativeSelect
                        required
                        value={dmOrderDetailsFormik.values.pickupSlot}
                        inputProps={{
                            name: "pickupSlot",
                            id: "pickupSlot",
                        }}
                        sx={{
                            fontSize: {
                                xs: ".8rem",
                                lg: ".8rem",
                            },
                            fontWeight: "fontWeightLight",
                            pt: {
                                xs: ".8rem",
                                lg: "1.2rem",
                            },
                        }}
                        onChange={(e) => {
                            if (e.target.value === "-1") {
                                return;
                            }
                            // setPickupSlot(e.target.value);
                            // setPickupSlotError("");
                            dmOrderDetailsFormik.handleChange(e);
                        }}
                        onBlur={dmOrderDetailsFormik.handleBlur}
                    >

                        <option value="-1">Select Slot</option>
                        {activeTimeSlots.includes("Lunch") ?
                            <option value="lunch" >Lunch</option> :
                            <option value="lunch" disabled>Lunch</option>}
                        {activeTimeSlots.includes("Dinner") ?
                            <option value="dinner">Dinner</option> :
                            <option value="dinner" disabled>Dinner</option>}
                    </NativeSelect>
                    {dmOrderDetailsFormik.touched.pickupSlot && dmOrderDetailsFormik.errors.pickupSlot && <FormHelperText error >{dmOrderDetailsFormik.errors.pickupSlot}</FormHelperText>}
                </FormControl>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <TextField variant="standard"
                        fullWidth
                        label="Add a note to your order"
                        onChange={(e) => {
                            setInstructions(e.target.value);
                        }
                        }
                    />
                </Grid>
            </Grid>




            <Grid container justifyContent="flex-end">
                <Button
                    disabled={cartItems.length === 0}
                    variant="contained"
                    onClick={() => handleButtonClick()}
                    sx={{ mt: 3, ml: 1 }}
                >
                    Place order
                </Button>
            </Grid>
            <LoadingIndicator />
        </div>
    );
};

export default PlaceOrder;