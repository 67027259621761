import React from "react";
import Header from "../components/Header";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import OrderMenu from "../components/menu/OrderMenu";
import { LoadingProvider } from "../context/loadingContext";
import { AppDataProvider } from "../context/appDataContext";
import UserState from "../context/userState";
import CartContextProvider from "../context/dmCartContext";
import CartDetails from "../components/menu/CartDetails";
import Grid from '@mui/material/Grid';
import PlaceOrder from "../components/menu/PlaceOrder";
import { AppDataContext } from "../context/appDataContext";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Button } from "@mui/material";

import "./DailyMenu.css";

const DailyMenu: React.FunctionComponent = () => {

    const appDataContext = React.useContext(AppDataContext);
    if (!appDataContext) {
        throw new Error("AppDataContext is not provided");
    }
    const { dailyMenu, dataLoaded } = appDataContext;

    return (
        <div className="home">
            <Header />
            <Nav />

            <UserState>
                <CartContextProvider>
                    <div className="dm-about-us-card">
                        <div className="dm-frame-parent5" >
                            {dataLoaded && dailyMenu && dailyMenu.length > 0 &&
                                <Grid container spacing={2} >
                                    <Grid item xs={12} sm={12} lg={6}>
                                        <OrderMenu menu={dailyMenu} />

                                    </Grid>

                                    <Grid item xs={12} sm={12} lg={6}>
                                        <CartDetails />

                                        <PlaceOrder menu={dailyMenu} />
                                    </Grid>

                                </Grid>
                            }
                            {
                                dataLoaded && dailyMenu && dailyMenu.length === 0 &&
                                <div className="dm-no-menu">
                                    <h4>Sorry! No menu today</h4>

                                    <p>Check back tomorrow. If you are looking for a party order, you can contact us on Whatsapp</p>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<WhatsAppIcon />}
                                        style={{ marginTop: '10px' }}
                                        onClick={() => window.open('https://wa.me/31622998661', '_blank')}
                                    >
                                        Chat on Whatsapp
                                    </Button>

                                </div>

                            }

                        </div>




                    </div>

                </CartContextProvider>
            </UserState>
            <Footer />
        </div>
    );
}
export default DailyMenu;