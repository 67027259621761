import React, { useState, useEffect, useContext } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box"

import UserDetails from "./UserDetails";
import Review from "./Review";
import CakeOrderDetails from "./CakeOrderDetails";

import UserContext from "../../context/userContext";
import { handleUpload } from "../utility/ImageUpload";
import LoadingIndicator from "../utility/LoadingIndicator";
import { useLoading } from "../../context/loadingContext";
import customFetch from "../../utils/api";
import whatsAppUrl from "../utility/WhatsappUrl";
import { useFormik, useFormikContext } from 'formik';
import * as Yup from 'yup';


const steps = ["Order Details", "User Details", "Review"];

interface StepContentProps {
  handleOrderDetailsChange?: any;
  handleUserDetailsChange?: any;
  orderDetails?: any;
  userDetails?: any;
  errors?: any;
  userErrors?: any;
  validateOrderDetails?: any;
  validateUserDetails?: any;
  cakeDetails?: any;
  userDetailsFormik?: any;
  orderDetailsFormik?: any;
}

interface CakeDetails {
  cakeDetails: any;
  flavour?: string;
}

function getStepContent(step: number, props: StepContentProps) {
  switch (step) {
    case 0:
      return (
        <CakeOrderDetails
          cakeDetails={props.cakeDetails}
          formik={props.orderDetailsFormik}
        />
      );
    case 1:
      return (
        <UserDetails

          formik={props.userDetailsFormik}
        />
      );
    case 2:
      return (
        <Review

          cakeDetails={props.cakeDetails}
          userDetailsFormik={props.userDetailsFormik}
          orderDetailsFormik={props.orderDetailsFormik}
        />
      );

    default:
      throw new Error("Unknown step");
  }
}

const OrderDetailSchema =
  Yup.object().shape({
    orderDetails: Yup.object().shape({
      flavour: Yup.string()
        .notOneOf(['-1'], 'Please select a flavour'),
      messageOnCake: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Message is required'),
      pickuptime: Yup.string()
        .required('Please select a pickup time'),
      customTheme: Yup.string(),
      images: Yup.array()
        .when('customTheme', {
          is: (customTheme: string) => customTheme === 'yes',
          then: (schema) => Yup.array().min(1, 'Please upload atleast one image'),
          otherwise: (schema) => Yup.array().min(0),
        }),

    })
  });

const UserDetailsSchema =
  Yup.object().shape({
    userDetails: Yup.object().shape({
      userName: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Name is required'),
      phone: Yup.string()
        .required('Phone number is required')
        .matches(/^(06\d{8}|\+31\d{9}|\+91\d{10})$/, "Must start with 06 or +31 or +91")
    })
  });

const Checkout: React.FunctionComponent<CakeDetails> = ({ cakeDetails, flavour }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const user_context = useContext(UserContext);

  const [submitOrder, setSubmitOrder] = useState(false);

  const { orderDetails, setOrderDetails } = user_context;
  const { loading, setLoading } = useLoading();

  const initialFlavour = flavour ? parseInt(flavour) : -1;


  const orderDetailsFormik = useFormik({
    initialValues: {
      orderDetails: {
        flavour: initialFlavour,
        messageOnCake: "",
        cakeSize: "0",
        pickuptime: "",
        customTheme: "no",
        images: [],
        price: 27,
        instructions: "",
        imageUrls: [],
        twoTier: "no",
      }
    },
    validationSchema: OrderDetailSchema,
    onSubmit: values => {
      console.log(values)
    },
  })

  const userDetailsFormik = useFormik({
    initialValues: {
      userDetails: {
        userName: "",
        phone: "",
      }
    },
    validationSchema: UserDetailsSchema,
    onSubmit: values => {
      console.log(values)
    },
  })




  const handleNext = async (orderDetails: any) => {
    if (activeStep === 0) {
      orderDetailsFormik.setTouched({
        orderDetails: {
          flavour: true,
          messageOnCake: true,
          pickuptime: true,
        }

      })
      const errors = await orderDetailsFormik.validateForm();
      if (Object.keys(errors).length === 0) {
        setActiveStep((prevStep) => prevStep + 1);
      }

    }
    if (activeStep === 1) {

      userDetailsFormik.setTouched({
        userDetails: {
          userName: true,
          phone: true,
        }
      })
      const errors = await userDetailsFormik.validateForm();
      if (Object.keys(errors).length === 0) {
        setActiveStep((prevStep) => prevStep + 1);
      }
    }
    if (activeStep === 2) {
      setIsButtonClicked(true);
      setLoading(true);
      const imagesUrls = await handleUpload(orderDetailsFormik.values.orderDetails.images);
      console.log(imagesUrls);

      orderDetailsFormik.setFieldValue('orderDetails.imageUrls', imagesUrls);
      setSubmitOrder(true);
    }
  };

  const cakeSizes = [
    { value: "0", label: "Small(4 servings)" },
    { value: "1", label: "Medium(6 servings)" },
    { value: "2", label: "Large(8-10 servings)" },
    { value: "3", label: "XL(12-15 servings)" },
    { value: "4", label: "XXL(20-25 servings)" },
    { value: "5", label: "XXXL(30-35 servings)" },
  ];

  useEffect(() => {
    if (submitOrder) {
      // update cakeSize to its corresponding label
      const cakeSizeLabel = cakeSizes.find(cakeSize => cakeSize.value === orderDetailsFormik.values.orderDetails.cakeSize)?.label;
      const updatedOrderDetails = {
        ...orderDetailsFormik.values.orderDetails,
        cakeSize: cakeSizeLabel
      };

      // api call to submit order
      customFetch(
        `${process.env.REACT_APP_API_URL}/order/cake/create`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...updatedOrderDetails,
            ...userDetailsFormik.values.userDetails,
          }),
        },
        setLoading
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("Success:", data);
          const url = whatsAppUrl(data);
          window.location.href = url;
        })
        .catch((error) => {
          console.error("Error:", error);
        });

      setSubmitOrder(false);
    }
  }, [submitOrder]);



  const updatePrice = (size: string) => {
    if (orderDetailsFormik.values.orderDetails.flavour !== -1) {
      const flavourDetails = cakeDetails.find(
        (cake: any) => cake.id == orderDetailsFormik.values.orderDetails.flavour
      );
      // set price in formik values based on size
      // if twoTier is 'yes' then price is  price 10

      let updatedPrice = flavourDetails["attributes"][1]["options"][size];
      if (orderDetailsFormik.values.orderDetails.twoTier === "yes") {
        updatedPrice = parseInt(updatedPrice) + 10;
      }
      orderDetailsFormik.setFieldValue('orderDetails.price', updatedPrice);
    }
  };



  React.useEffect(() => {
    updatePrice(orderDetailsFormik.values.orderDetails.cakeSize);
  }, [orderDetailsFormik.values.orderDetails.cakeSize, orderDetailsFormik.values.orderDetails.flavour, orderDetailsFormik.values.orderDetails.twoTier]);


  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  let steComponent;
  if (activeStep === 0) {
    steComponent = getStepContent(activeStep, {

      cakeDetails,
      orderDetailsFormik
    });
  } else if (activeStep === 1) {
    steComponent = getStepContent(activeStep, {

      userDetailsFormik
    });
  } else {
    steComponent = getStepContent(activeStep, {

      cakeDetails,
      userDetailsFormik,
      orderDetailsFormik
    });
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Container component="main" maxWidth="lg" sx={{ mb: 4 }}>
        <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography variant="h5" gutterBottom>
              Thank you for your order.
            </Typography>
            <Typography variant="subtitle1">
              Your order number is #2001539. We have emailed your order
              confirmation, and will send you an update when your order has
              shipped.
            </Typography>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {steComponent}
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              {activeStep !== 0 && (
                <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                  Back
                </Button>
              )}
              <Button
                variant="contained"
                onClick={() => handleNext(orderDetails)}
                sx={{ mt: 3, ml: 1 }}
              >
                {activeStep === steps.length - 1 ? "Place order" : "Next"}
              </Button>
            </Box>
            <LoadingIndicator />
          </React.Fragment>
        )}
        {/* </Paper> */}
      </Container>
    </React.Fragment>
  );
};

export default Checkout;

