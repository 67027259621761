import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export interface UserDetailsProps {
  // onUserDetailsChange: (details: { [key: string]: string }) => void;
  // userDetails: any;
  // userErrors: any;
  // validateUserDetails: any;
  formik: any;
}

const UserDeatils: React.FunctionComponent<UserDetailsProps> = ({
  // onUserDetailsChange,
  // userDetails,
  // userErrors,
  // validateUserDetails,
  formik
}) => {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        User Details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="userDetails.userName"
            name="userDetails.userName"
            label="Name"
            fullWidth
            autoComplete="given-name"
            variant="standard"
            error={Boolean(formik.touched.userDetails?.userName && formik.errors.userDetails?.userName)}
            helperText={formik.touched.userDetails?.userName && formik.errors.userDetails?.userName}
            value={formik.values.userDetails.userName}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              // onUserDetailsChange({ userName: e.target.value });
              formik.handleChange(e);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="userDetails.phone"
            name="userDetails.phone"
            label="Mobile Number"
            fullWidth
            autoComplete="mobile-number"
            variant="standard"
            error={Boolean(formik.touched.userDetails?.phone && formik.errors.userDetails?.phone)}
            helperText={formik.touched.userDetails?.phone && formik.errors.userDetails?.phone}
            value={formik.values.userDetails.phone}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              // onUserDetailsChange({ phone: e.target.value });
              formik.handleChange(e);
            }}
            inputProps={{ maxLength: 13 }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default UserDeatils;
