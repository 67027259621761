import { FunctionComponent, useState } from "react";
import "./Nav.css";
import { useNavigate } from "react-router-dom";
import indusLogo from "../images/indusLogo.png";
const Nav: FunctionComponent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleButtonClick = (path: string) => {
    navigate(path); // Navigate to the /about route
  };
  return (
    <div className="nav">
      <div className="frame-parent">
        <div className="frame-wrapper">
          <div
            className="screenshot-2023-08-13-at-1610-parent"
            onClick={() => handleButtonClick("/")}
            style={{ cursor: "pointer" }}
          >
            <img
              className="screenshot-2023-08-13-at-1610"
              alt=""
              src={indusLogo}
            />
            <div className="industfc">IndusTFC</div>
          </div>
        </div>
        <button
          className="hamburger"
          onClick={() => setIsOpen(!isOpen)}
          aria-label="Toggle navigation"
        >
          ☰
        </button>
        <div className={`frame-group ${isOpen ? "open" : ""}`}>
          <div className="about-us-wrapper">
            <div
              className="about-us"
              onClick={() => handleButtonClick("/aboutus")}
              style={{ cursor: "pointer" }}
            >
              About us
            </div>
          </div>
          <div className="about-us-wrapper">
            <div className="about-us"
              onClick={() => handleButtonClick("/menu")}
              style={{ cursor: "pointer" }}
            >Today's Menu</div>
          </div>
          <div className="about-us-wrapper">
            <div className="about-us"
              onClick={() => handleButtonClick("/detail")}
              style={{ cursor: "pointer" }}
            >
              Order Cake</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
