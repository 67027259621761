import { createContext } from "react";
import { set } from "react-hook-form";
interface OrderDetailsType {
  flavour: number | string;
  messageOnCake: string;
  cakeSize: string;
  pickuptime: string;
  customTheme: string;
  images: string[];
  price: number;
  instructions: string;
  imageUrls: string[];
  twoTier: string;
}

interface UserContextType {
  orderDetails: OrderDetailsType;
  setOrderDetails: React.Dispatch<React.SetStateAction<OrderDetailsType>>;
}
const UserContext = createContext<UserContextType>({
  orderDetails: {
    flavour: 25,
    messageOnCake: "",
    cakeSize: "0",
    pickuptime: "",
    customTheme: "no",
    images: [],
    price: 27,
    instructions: "",
    imageUrls: [],
    twoTier: "no",
  },
  setOrderDetails: (details: any) => {},
});

export default UserContext;
