import { FunctionComponent, useEffect, useState } from "react";
import Header from "../components/Header";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import Checkout from "../components/checkout/Checkout";

import "./Details.css";

const CheckoutPage: FunctionComponent = () => {
  const [cakeDetails, setCakeDetails] = useState<any>(null);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);

  useEffect(() => {
    fetch("http://localhost:4400/api/v1/cakes")
      .then((response) => response.json())
      .then((data) => {
        setCakeDetails(data);
        setDataLoaded(true);
      })
      .catch((error) =>
        console.error("Error fetching product details:", error)
      );
  }, []);
  return (
    <div className="details">
      <Header />
      <Nav />
      {dataLoaded && <Checkout cakeDetails={cakeDetails} />}

      <Footer />
    </div>
  );
};

export default CheckoutPage;
