import { FunctionComponent } from "react";
import "./HowItWorks.css";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { grey } from "@mui/material/colors";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const HowItWorks: FunctionComponent = () => {
  return (
    <div className="how-it-works">
      <div className="frame-parent7">
        <div className="how-it-works-parent">
          <div className="how-it-works1">How it works</div>
          {/* <div className="lorem-ipsum-dolor">
            Lorem ipsum dolor sit amet consectetur. Imperdiet curabitur sed
            scelerisque nunc in velit. Risus leo faucibus massa sed mollis eget
            r
          </div> */}
        </div>
        <div className="frame-wrapper8">
          <div className="question-parent">
            {/* <div className="question">
              <div className="lorem-ipsum-dolor-container">
                <ul className="lorem-ipsum-dolor1">
                  Lorem ipsum dolor sit amet consectetur. Imperdiet
                </ul>
              </div>
            </div>
            <div className="question">
              <div className="lorem-ipsum-dolor-container">
                <ul className="lorem-ipsum-dolor1">
                  Lorem ipsum dolor sit amet consectetur. Imperdiet
                </ul>
              </div>
            </div>
            <div className="question">
              <div className="lorem-ipsum-dolor-container">
                <ul className="lorem-ipsum-dolor1">
                  Lorem ipsum dolor sit amet consectetur. Imperdiet
                </ul>
              </div>
            </div>
            <div className="question">
              <div className="lorem-ipsum-dolor-container">
                <ul className="lorem-ipsum-dolor1">
                  Lorem ipsum dolor sit amet consectetur. Imperdiet
                </ul>
              </div>
            </div> */}
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Item>
                    <Typography variant="h6" color={grey[900]}>
                      Browse & Select
                    </Typography>
                    <Typography variant="body2">
                      Explore our diverse menu filled with delectable food and
                      cake options to satisfy your cravings
                    </Typography>
                  </Item>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Item>
                    <Typography variant="h6" color={grey[900]}>
                      Place Your Order
                    </Typography>
                    <Typography variant="body2">
                      Once you've made your choice, easily place your order
                      directly through our website. No payment needed at this
                      stage!
                    </Typography>
                  </Item>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Item>
                    <Typography variant="h6" color={grey[900]}>
                      Instant Notification
                    </Typography>
                    <Typography variant="body2">
                      After placing your order, you'll instantly receive a
                      confirmation message on WhatsApp with your order details
                    </Typography>
                  </Item>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Item>
                    <Typography variant="h6" color={grey[900]}>
                      Pick Up
                    </Typography>
                    <Typography variant="body2">
                      Head over to our location at the specified time to pick up
                      your delicious order
                    </Typography>
                  </Item>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Item>
                    <Typography variant="h6" color={grey[900]}>
                      Easy Payment
                    </Typography>
                    <Typography variant="body2">
                      No need for upfront payments! When you arrive to pick up
                      your order, we'll share a convenient payment link with
                      you. Simply click on the link and follow the prompts to
                      make your payment
                    </Typography>
                  </Item>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Item>
                    <Typography variant="h6" color={grey[900]}>
                      Enjoy!
                    </Typography>
                  </Item>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
