import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Typography } from '@mui/material';
import React from 'react';
import Paper from '@mui/material/Paper';
import { CartContext } from '../../context/dmCartContext';
import Stack from '@mui/material/Stack';
import styled from '@mui/material/styles/styled';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import NativeSelect from '@mui/material/NativeSelect';
import Grid from '@mui/material/Grid';
import { AppDataContext } from '../../context/appDataContext';
import Input from '@mui/material/Input';
import InputProps from '@mui/material/Input';


const moreDetailsAttributeMapping: any = {
    spice_level: "Spice Level",
    quantity: "Quantity",
    rice_roti_option: "Rice/Roti Option",
    extra_paratha: "Extra Paratha",
};

const renderOptions = (item: any, menuItem: any, key: string) => {
    if (typeof item.moreDetails[key] === 'object') {
        // Find the relevant metadata for the item
        const relevantMetaData = menuItem.meta_data.find((meta: any) =>
            meta.value.fields.find((field: any) => field.label === moreDetailsAttributeMapping[key])
        );

        // Map over the choices in the options
        return relevantMetaData.value.fields.map((field: any) => {
            return field.options.choices.map((option: any, optionIndex: number) => {
                // Return an option element for each choice
                return (
                    <option value={option.label} data-attribute={option.pricing_amount}>{option.label}</option>

                )
            })
        })

    } else {
        return menuItem.attributes.find((attribute: any) => attribute.name.replace('*', '') === moreDetailsAttributeMapping[key]).options.map((option: string, optionIndex: number) => {
            return (
                <option value={option}>{option}</option>
            )
        })
    }
}
const CartDetails: React.FunctionComponent = () => {
    const cartContext = React.useContext(CartContext);
    if (!cartContext) {
        throw new Error("AppDataContext is not provided");
    }
    const { cartItems, setCartItems } = cartContext;

    const appDataContext = React.useContext(AppDataContext);
    if (!appDataContext) {
        throw new Error("AppDataContext is not provided");
    }
    const { dailyMenu } = appDataContext;

    const handleDelete = (id: string) => {
        const updatedCart = cartItems.filter((item: any) => item.id !== id);
        setCartItems(updatedCart);
    };
    const [isEditing, setIsEditing] = React.useState(false);
    const [editingKey, setEditingKey] = React.useState("");
    const handleEdit = (cartItemId: any) => {

        setIsEditing(true);
        setEditingKey(cartItemId);
    }
    const handleSelectChange = (cartItemId: string, key: string, event: any, priceAttribute: any) => {
        // find the cart item
        const itemToUpdate = cartItems.find((item: any) => item.id === cartItemId);

        // update the moreDetails attribute
        if (typeof itemToUpdate.moreDetails[key] === 'object') {
            const dailymenuItem = dailyMenu.find((menu: any) => menu.id === itemToUpdate.menuItem.id);
            const relevantMetaData = dailymenuItem.meta_data.find((meta: any) =>
                meta.value.fields.find((field: any) => field.label === moreDetailsAttributeMapping[key])
            );
            //TODO: Fix me, remove hard coding
            priceAttribute = relevantMetaData.value.fields.find((field: any) => field.label === moreDetailsAttributeMapping[key]).options.choices.find((choice: any) => choice.label === "Yes").pricing_amount;


            itemToUpdate.moreDetails[key].label = event.target.value;

            if (itemToUpdate.moreDetails[key].label === 'Yes' && itemToUpdate.menuItem.isPriceUpdated === false) {
                itemToUpdate.menuItem.price = parseFloat(itemToUpdate.menuItem.price) + parseFloat(priceAttribute);
                itemToUpdate.menuItem.isPriceUpdated = true;
            }
            else if (itemToUpdate.moreDetails[key].label === 'No' && itemToUpdate.menuItem.isPriceUpdated === true) {
                itemToUpdate.menuItem.price = parseFloat(itemToUpdate.menuItem.price) - parseFloat(priceAttribute);
                itemToUpdate.menuItem.isPriceUpdated = false;
            }
        }

        else
            itemToUpdate.moreDetails[key] = event.target.value;
        // update the cartItems state

        const updatedCart = cartItems.map((item: any) => {
            if (item.id === cartItemId) {
                return itemToUpdate;
            }
            return item;
        });
        setCartItems(updatedCart);
        setIsEditing(false);
    }

    const handleQuantityChange = (cartItemId: string, event: any) => {
        // find the cart item
        const itemToUpdate = cartItems.find((item: any) => item.id === cartItemId);

        let value = parseInt(event.target.value, 10);

        if (value > 10) value = 10;
        if (value < 1) value = 1;

        // update quantity
        itemToUpdate.quantity = value;
        // update the cartItems state
        const updatedCart = cartItems.map((item: any) => {
            if (item.id === cartItemId) {
                return itemToUpdate;
            }
            return item;
        });
        setCartItems(updatedCart);
        // setIsEditing(false);
    }

    const getDisplayValue = (key: string, item: any) => {
        if (typeof item.moreDetails[key] === 'object') {
            return `${item.moreDetails[key].label} `;
        }

        // if (key.includes("extra")) {
        //     return item.moreDetails[key] === 'no' ? "No" : "Yes";
        // }
        return item.moreDetails[key];
    }


    React.useEffect(() => {

    }, [cartItems]);


    return (
        <div>
            <Paper style={{ paddingLeft: '20px', paddingTop: '20px' }}>
                <Typography variant="h6" component="div">
                    Order Details
                </Typography>
                <List>
                    {cartItems.map((item: any, index: number) => {
                        //  find the menu item from the daily menu
                        const menuItem = dailyMenu.find((menu: any) => menu.id === item.menuItem.id);

                        return (
                            <div>

                                <ListItem key={index}
                                    secondaryAction={
                                        <IconButton onClick={() => handleDelete(item.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    }>
                                    <ListItemText primary={
                                        <React.Fragment>
                                            <Grid item xs={12} container direction="row" alignItems="center" >
                                                <Typography variant="body2" component="div">
                                                    {item.menuItem.name}
                                                </Typography>
                                                {<IconButton size="small" onClick={() => handleEdit(item.id)}>
                                                    <EditIcon fontSize="small" />
                                                </IconButton>}

                                            </Grid>

                                        </React.Fragment>}

                                        secondary={
                                            <React.Fragment>
                                                {item.quantity && (
                                                    <Grid item xs={12} container direction="row" alignItems="center" >
                                                        {!(isEditing && editingKey === item.id) && (
                                                            <Typography variant="body2" component="div">
                                                                Quantity: {item.quantity}
                                                            </Typography>
                                                        )}

                                                        {isEditing && editingKey === item.id && (
                                                            <Typography variant="body2" component="div">
                                                                Quantity: <Input type="number"
                                                                    value={item.quantity}

                                                                    inputProps={{ min: 1, max: 10 }}
                                                                    onChange={(event) => {
                                                                        handleQuantityChange(item.id, event);

                                                                    }}
                                                                    onBlur={() => setIsEditing(false)}
                                                                />
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                )}

                                                {item.moreDetails && Object.keys(item.moreDetails).map((key: any, index: number) => (
                                                    <div>
                                                        <Grid item xs={12} container direction="row" alignItems="center" >
                                                            <Typography variant="body2" component="div">
                                                                {moreDetailsAttributeMapping[key]}: {!(isEditing && editingKey === item.id) && getDisplayValue(key, item)}
                                                                {isEditing && editingKey === item.id && (
                                                                    <NativeSelect
                                                                        value={typeof item.moreDetails[key] === 'object' ? item.moreDetails[key].label : item.moreDetails[key]}
                                                                        onChange={(event) => handleSelectChange(item.id, key, event, event.target.getAttribute('data-attribute'))}
                                                                    >
                                                                        <React.Fragment>
                                                                            {renderOptions(item, menuItem, key)}
                                                                        </React.Fragment>
                                                                    </NativeSelect>


                                                                )}
                                                            </Typography>

                                                        </Grid>

                                                    </div>

                                                ))}

                                            </React.Fragment>
                                        }

                                    />





                                </ListItem>
                                {/* show diviider if its the last item */}
                                {index !== cartItems.length - 1 && <Divider />}

                            </div>
                        )
                    }

                    )}
                </List>

                <Typography variant="h6" component="div">
                    Total: {cartItems.reduce((acc: any, item: any) => acc + parseFloat(item.menuItem.price) * item.quantity, 0)}€
                </Typography>

            </Paper>
        </div>
    );
}

export default CartDetails;
