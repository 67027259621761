// api.ts

const customFetch = async (
  input: RequestInfo,
  init?: RequestInit,
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (setLoading) setLoading(true);

  try {
    const response = await fetch(input, init);
    if (setLoading) setLoading(false);
    return response;
  } catch (error) {
    if (setLoading) setLoading(false);
    throw error;
  }
};

export default customFetch;
