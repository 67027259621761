import React from "react";
import UserContext from "./userContext";

interface OrderDetailsType {
  flavour: number | string;
  messageOnCake: string;
  cakeSize: string;
  pickuptime: string;
  customTheme: string;
  images: string[];
  price: number;
  instructions: string;
  imageUrls: string[];
  twoTier: string;
}
const UserState = (props: any) => {
  const [orderDetails, setOrderDetails] = React.useState<OrderDetailsType>({
    flavour: -1,
    messageOnCake: "",
    cakeSize: "0",
    pickuptime: "",
    customTheme: "no",
    images: [],
    price: 0,
    instructions: "",
    imageUrls: [],
    twoTier: "no",
  });
  return (
    <UserContext.Provider value={{ orderDetails, setOrderDetails }}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserState;
