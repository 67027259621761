import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBjfbMUbKA4-w44zWUBUBo7A9bb4BsM6Yo",
  authDomain: "industfc-5fc03.firebaseapp.com",
  projectId: "industfc-5fc03",
  storageBucket: "industfc-5fc03.appspot.com",
  messagingSenderId: "915251842900",
  appId: "1:915251842900:web:50d18d4e5f993c4e1f02c1",
  measurementId: "G-ZB4DFY514C",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app);

export { storage, app as default };
